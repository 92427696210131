import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo/SeedAfrique-Org-Logo.svg";
import { IoCloseCircleOutline } from "react-icons/io5";
import { GoHomeFill } from "react-icons/go";
import { GrFormNext } from "react-icons/gr";
import {
  FaCircleInfo,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaXTwitter,
} from "react-icons/fa6";

import { FaHandshakeSimple } from "react-icons/fa6";
import { MdEvent } from "react-icons/md";

import { IoMdImages } from "react-icons/io";
import { FaBlog } from "react-icons/fa";
import { MdContacts } from "react-icons/md";
import { MdOutlineAdminPanelSettings } from "react-icons/md";

export default function Header() {
  return (
    <>
      <TopNavBar />
      <div className="md:h-0.5 md:mx-8 md:bg-black"></div>
    </>
  );
}

const TopNavBar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div>
      {isSidebarOpen && (
        <div
          className="fixed top-0 left-0 h-screen w-screen bg-green-950 opacity-50 z-40 backdrop-filter backdrop-blur-lg"
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Mobile */}
      <div className="flex justify-center ">
        <div
          className={`fixed right-0 h-screen  w-3/4 bg-paleGreen z-50 ${
            isSidebarOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out`}
        >
          <div className="flex flex-col justify-between max-h-screen  ">
            {/* top section */}
            <div>
              {/* Top */}
              <div className=" flex justify-between items-center px-3">
                {/* logo */}
                <div>
                  <img
                    src={logo}
                    alt="SEED Afrique Logo"
                    width="60"
                    height="60"
                    className="mt-1"
                  />
                </div>

                {/* close button */}
                <div>
                  <IoCloseCircleOutline
                    className="h-7 w-7 text-darkGreen"
                    onClick={toggleSidebar}
                  />
                </div>
              </div>

              {/* Middle */}
              <div>
                {/* line */}
                <div className="w-full h-[1px] bg-gray-500 mb-4"></div>

                <Link to="/home" onClick={toggleSidebar}>
                  {/* Home icon */}
                  <div className="flex items-center justify-between px-3">
                    <div className="flex items-center">
                      <GoHomeFill className="h-6 w-6 text-darkGreen " />
                      <p className="text-base ml-2 font-semibold text-darkGreen font-Poppins">
                        Home
                      </p>
                    </div>
                    <div className="border-2 border-darkGreen rounded-md">
                      <GrFormNext className="text-darkGreen" />
                    </div>
                  </div>
                </Link>

                {/* line */}
                <div className="w-full h-[1px] bg-gray-500 my-4"></div>

                <Link to="/about" onClick={toggleSidebar}>
                  {/* About */}
                  <div className="flex items-center justify-between px-3">
                    <div className="flex items-center">
                      <FaCircleInfo className="h-6 w-6 text-darkGreen" />
                      <p className="ml-2 text-base text-darkGreen font-semibold font-Poppins">
                        About
                      </p>
                    </div>
                    <div className="border-2 border-darkGreen rounded-md">
                      <GrFormNext className="text-darkGreen" />
                    </div>
                  </div>
                </Link>

                {/* line */}
                <div className="w-full h-[1px] bg-gray-500 my-4"></div>

                <Link to="/joinus" onClick={toggleSidebar}>
                  {/* About */}
                  <div className="flex items-center justify-between px-3">
                    <div className="flex items-center">
                      <FaHandshakeSimple className="h-6 w-6 text-darkGreen" />
                      <p className="ml-2 text-base text-darkGreen font-semibold font-Poppins">
                        Join Us
                      </p>
                    </div>
                    <div className="border-2 border-darkGreen rounded-md">
                      <GrFormNext className="text-darkGreen" />
                    </div>
                  </div>
                </Link>

                {/* line */}
                <div className="w-full h-[1px] bg-gray-500 my-4"></div>

                <Link to="/upcomingevents" onClick={toggleSidebar}>
                  {/* About */}
                  <div className="flex items-center justify-between px-3">
                    <div className="flex items-center">
                      <MdEvent className="h-6 w-6 text-darkGreen" />
                      <p className="ml-2 text-base text-darkGreen font-semibold font-Poppins">
                        Events
                      </p>
                    </div>
                    <div className="border-2 border-darkGreen rounded-md">
                      <GrFormNext className="text-darkGreen" />
                    </div>
                  </div>
                </Link>

                {/* line */}
                <div className="w-full h-[1px] bg-gray-500 my-4"></div>

                <Link to="/contacts" onClick={toggleSidebar}>
                  {/* Contact */}
                  <div className="flex items-center justify-between px-3">
                    <div className="flex items-center">
                      <MdContacts className="h-6 w-6 text-darkGreen" />
                      <p className="ml-2 text-base font-semibold font-Poppins text-darkGreen">
                        Contact
                      </p>
                    </div>
                    <div className="border-2 border-darkGreen rounded-md">
                      <GrFormNext className="text-darkGreen" />
                    </div>
                  </div>
                </Link>

                {/* line */}
                <div className="w-full h-[1px] bg-gray-500 my-4"></div>

                <Link to="/admin/adminDashboard" onClick={toggleSidebar}>
                  {/* Admin */}
                  <div className="flex items-center justify-between px-3">
                    <div className="flex items-center">
                      <MdOutlineAdminPanelSettings className="h-6 w-6 text-darkGreen" />
                      <p className="ml-2 text-base text-darkGreen font-semibold font-Poppins">
                        Admin
                      </p>
                    </div>
                    <div className="border-2 border-darkGreen rounded-md">
                      <GrFormNext className="text-darkGreen" />
                    </div>
                  </div>
                </Link>

                {/* line */}
                <div className="w-full h-[1px] bg-gray-500 my-4"></div>
              </div>
            </div>

            {/* Socials */}
            {/* Icons at the bottom */}
            <div className="lex">
              <div className="flex justify-center">
                <div className="flex bg-gray-400 px-2 py-2 rounded-xl space-x-2">
                  <a
                    href="https://www.facebook.com/seedafrique?mibextid=LQQJ4d"
                    alt="Our Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <div className="bg-paleGreen p-1 rounded-md">
                      <FaFacebook
                        className="h-6 w-6"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/seedafrique?igsh=MXI4ZXIycmZqbzZjdA=="
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    <div className="bg-paleGreen p-1 rounded-md">
                      <FaInstagram
                        className="h-6 w-6"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </a>

                  <a
                    href="https://x.com/seedafrique?s=21"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="bg-paleGreen p-1 rounded-md">
                      <FaXTwitter
                        className="h-6 w-6"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </a>

                  <a
                    href="https://www.linkedin.com/company/seedafrique/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="bg-paleGreen p-1 rounded-md">
                      <FaLinkedin
                        className="h-6 w-6"
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Desktop */}

      <nav className="md:bg-transparent bg-emerald-500 md:py-0 md:px-2 px-5 p-2 flex w-full justify-between items-center z-20">
        {/* The SeedAfrique logo */}
        <div
          className="flex items-center
        "
        >
          <img
            src={logo}
            alt="Logo"
            className="md:h-24 md:w-24 h-11 w-11 ml-4"
            onClick={toggleSidebar}
            style={{ cursor: "pointer" }}
          />
        </div>
        {/* The button to open the side bar */}
        <div className="md:hidden flex justify-end items-center">
          <svg
            viewBox="-0.5 -0.5 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            id="Siderbar--Streamline-Solar-Ar"
            height={30}
            width={30}
            onClick={toggleSidebar}
            style={{ cursor: "pointer" }}
          >
            <desc>{"Siderbar Streamline Icon: https://streamlinehq.com"}</desc>
            <path
              d="M1.25 6.875c0 -2.357025 0 -3.53553125 0.73223125 -4.26776875C2.71446875 1.875 3.8929750000000003 1.875 6.25 1.875h2.5c2.3569999999999998 0 3.5355625 0 4.26775 0.73223125C13.75 3.33946875 13.75 4.517975 13.75 6.875v1.25c0 2.3569999999999998 0 3.5355625 -0.73225 4.26775C12.285562500000001 13.125 11.107 13.125 8.75 13.125h-2.5c-2.357025 0 -3.53553125 0 -4.26776875 -0.73225C1.25 11.660562500000001 1.25 10.482 1.25 8.125v-1.25Z"
              stroke="#000000"
              strokeWidth={1}
            />
            <path
              d="M3.4375 6.25h3.75"
              stroke="#000000"
              strokeLinecap="round"
              strokeWidth={1}
            />
            <path
              d="M4.0625 8.75h2.5"
              stroke="#000000"
              strokeLinecap="round"
              strokeWidth={1}
            />
            <path
              d="m9.375 13.125 0 -11.25"
              stroke="#000000"
              strokeLinecap="round"
              strokeWidth={1}
            />
          </svg>
        </div>

        {/* The links that appear only on the desktop view */}
        <div className="hidden md:flex items-center space-x-4 mr-6">
          <Link
            to="/home"
            className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out"
          >
            Home
          </Link>
          <Link
            to="/about"
            className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out"
          >
            About Us
          </Link>
          <Link
            to="/joinus"
            className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out"
          >
            Join Us
          </Link>
          <Link
            to="/upcomingevents"
            className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out"
          >
            Events
          </Link>

          {/* The Desktop Nav for the Blog and Gallery */}
          {/* <Link
            to="/gallery"
            className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out"
          >
            Gallery
          </Link>
          <Link
            to="/blog"
            className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out"
          >
            Blog
          </Link> */}

          <Link
            to="/contacts"
            className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out"
          >
            Contact Us
          </Link>
          <Link
            to="/admin/adminDashboard"
            className="text-gray-400 hover:text-emerald-900 hover:underline transition duration-300 ease-in-out"
          >
            Admin
          </Link>
        </div>
      </nav>
    </div>
  );
};
