import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import DOMPurify from "dompurify";
import { Blurhash } from "react-blurhash";
import { BiMenuAltRight } from "react-icons/bi";

function UpcomingEvents() {
  const [events, setEvents] = useState([]); // Stores all events
  const [filteredEvents, setFilteredEvents] = useState([]); // Stores filtered events
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [imageLoaded, setImageLoaded] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const eventRefs = useRef([]); // To store references to each event card
  const [filterType, setFilterType] = useState("upcoming"); // 'upcoming' or 'past'
  const [dropdownVisible, setDropdownVisible] = useState(false); // Controls overlay visibility

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://seedafriqueswegp-backend.onrender.com/api/events"
        );
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const filteredAndSortedEvents = response.data
          .filter((event) => new Date(event.eventDate) >= today)
          .sort((a, b) => new Date(a.eventDate) - new Date(b.eventDate))
          .map((event) => {
            const eventDate = new Date(event.eventDate);
            const options = {
              weekday: "short",
              year: "numeric",
              month: "short",
              day: "numeric",
            };
            const formattedDate = new Intl.DateTimeFormat(
              "en-US",
              options
            ).format(eventDate);
            const [day, month, dayNumber, year] = formattedDate
              .replace(/,/g, "")
              .split(" ");
            return { ...event, day, dayNumber, month, year };
          });

        setUpcomingEvents(filteredAndSortedEvents);
        setLoading(false);
      } catch (err) {
        console.error("API Error:", err);
        setError("Failed to load events. Please try again later.");
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const filtered =
      filterType === "upcoming"
        ? events.filter((event) => new Date(event.eventDate) >= today)
        : events.filter((event) => new Date(event.eventDate) < today);

    setFilteredEvents(filtered);
  }, [filterType, events]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = entry.target.getAttribute("data-index");
            setImageLoaded((prev) => ({ ...prev, [index]: true }));
          }
        });
      },
      {
        rootMargin: "0px 0px 200px 0px", // Adjust this threshold for early loading
        threshold: 0.1,
      }
    );

    eventRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      eventRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, [upcomingEvents]);

  const toggleText = (index) =>
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));

  const charLimit = 40;

  const stripHtmlTags = (html) => {
    const tmp = document.createElement("div");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  if (loading) {
    return <Skeleton />;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="mx-2 sm:mx-5 mb-7 mt-2 sm:mt-7 text-center">
      {/* Top side with the text and button for dropdown */}
      <div className="flex justify-between sm:justify-center sm:space-x-3">
        <div>
          <h1 className="text-left text-lg sm:text-3xl text-emerald-900 font-bold sm:font-semibold antialiased font-Poppins mb-1">
            Upcoming Events
          </h1>
          <h2 className="text-sm sm:text-xl text-emerald-900 font-normal antialiased mb-5">
            Stay Updated to know what's next.
          </h2>
        </div>
        <div>
          {/* Button for dropdown overlay */}
          <button className="bg-blue-300 rounded-md p-2"
          
          onClick={() => setDropdownVisible(!dropdownVisible)}
          >
            <BiMenuAltRight size={24} />
          </button>
        </div>
      </div>

      {/* Dropdown overlay */}
      {dropdownVisible && (
        <div className="absolute top-28 right-5 bg-white shadow-lg rounded-lg p-4 z-50">
          <h3 className="text-xl font-semibold mb-3">Filter Events</h3>
          <button
            className={`block w-full text-left py-2 px-3 rounded ${
              filterType === "upcoming"
                ? "bg-green-200 text-green-800"
                : "hover:bg-gray-100"
            }`}
            onClick={() => {
              setFilterType("upcoming");
              setDropdownVisible(false);
            }}
          >
            Upcoming Events
          </button>
          <button
            className={`block w-full text-left py-2 px-3 rounded mt-2 ${
              filterType === "past"
                ? "bg-green-200 text-green-800"
                : "hover:bg-gray-100"
            }`}
            onClick={() => {
              setFilterType("past");
              setDropdownVisible(false);
            }}
          >
            Past Events
          </button>
        </div>
      )}

      <div className="flex flex-col items-center space-y-10">
        {upcomingEvents.map((item, index) => {
          const isExpanded = expandedIndex === index;
          const sanitizedDescription = DOMPurify.sanitize(item.description);
          const plainDescription = stripHtmlTags(sanitizedDescription);
          const truncatedDescription = plainDescription.slice(0, charLimit);

          return (
            <div
              key={index}
              className="w-full max-w-md mx-auto pb-3 rounded-2xl shadow-lg flex flex-col items-center bg-deeperpalegreen"
              data-index={index} // Used to identify each card for Intersection Observer
              ref={(el) => (eventRefs.current[index] = el)} // Store ref for each event card
            >
              <div className="w-full h-auto relative rounded-2xl overflow-hidden mb-4">
                {!imageLoaded[index] && (
                  <div className="w-full h-64 inset-0 rounded-2xl overflow-hidden">
                    <Blurhash
                      hash={
                        item.blurHash ||
                        "VT5ap~fQeogff*d8e:e:b]fQcqf*e.fkf7gfg2f+e.eo"
                      }
                      width="100%"
                      height="100%"
                      resolutionX={32}
                      resolutionY={32}
                      punch={1}
                    />
                  </div>
                )}
                {imageLoaded[index] && (
                  <img
                    src={
                      item.imageUrl ||
                      "https://firebasestorage.googleapis.com/v0/b/seedafrique-5751a.appspot.com/o/Home%20Page%20images%2FSeedAfTogether2resized.png?alt=media&token=ed49daec-587a-42b0-ab5e-4af04a24462"
                    }
                    alt={item.title || "Event Image"}
                    className="w-full h-auto object-cover rounded-2xl"
                  />
                )}
                <div className="absolute top-2 right-2 bg-lime-50 border-4 border-green-800 text-green-800 text-xs font-bold rounded-lg p-2">
                  <p>{item.day}</p>
                  <p>{item.dayNumber}</p>
                  <p>{`${item.month} ${item.year}`}</p>
                </div>
              </div>

              <div className=" ml-7 w-full items-start">
                <h2 className="right-0 w-2/4 text-sm bg-green-800 text-lime-50 text-center mt-2 py-1 px-3 rounded-md font-Poppins">
                  {item.category || "Uncategorized"}
                </h2>
              </div>
              <h1 className="text-lg text-left w-full font-semibold text-green-950 mt-2 px-4 font-Poppins">
                {item.title || "Untitled Event"}
              </h1>

              <p className="text-emerald-900 text-left w-full text-sm px-4 font-Poppins mt-3 ">
                {isExpanded ? (
                  <span
                    className="mt-5"
                    dangerouslySetInnerHTML={{
                      __html: sanitizedDescription,
                    }}
                  ></span>
                ) : (
                  <span className="mr-2">{truncatedDescription}...</span>
                )}
                {item.description.length > charLimit && (
                  <button
                    className="text-blue-500 underline"
                    onClick={() => toggleText(index)}
                  >
                    {isExpanded ? "Show Less" : "More"}
                  </button>
                )}
              </p>

              {/* The venue and register icons at the bottom.  */}
              <div className="flex justify-between items-center mt-5 mb-2 px-4 w-full">
                <a
                  className="text-emerald-900 underline text-left"
                  href={item.googleMapLink || "/joinus"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.venue || "No venue specified"}
                </a>
                <a
                  className="text-blue-600 underline"
                  href={item.registrationLink || "/joinus"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Register
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default UpcomingEvents;

const Skeleton = () => {
  const numberOfBlocks = 12; // Adjust the number of skeleton blocks as needed

  return (
    <div className="space-y-6 w-full max-w-md mx-auto px-2 mb-7 mt-2 sm:mt-7 rounded-2xl">
      {/* Header skeleton loaders */}
      <div className="flex flex-col items-center justify-center space-y-2">
        <div className="w-44 h-5 bg-gray-300 rounded animate-shimmer"></div>
        <div className="w-44 h-5 bg-gray-300 rounded animate-shimmer"></div>
      </div>

      {/* Skeleton content blocks with shimmer */}
      {Array.from({ length: numberOfBlocks }).map((_, index) => (
        <div
          key={index}
          className="w-full h-96 bg-gray-300 rounded-xl relative overflow-hidden"
        >
          {/* Shimmer effect */}
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-gray-200 to-transparent animate-shimmer"></div>
        </div>
      ))}
    </div>
  );
};

// commit Save 2
// commit Save 3
// Commit Save 4
