import React, { useState, useEffect } from "react";
import { RiUserAddLine } from "react-icons/ri";
import { IoIosPeople } from "react-icons/io";
import { LuSettings2 } from "react-icons/lu";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { GrContactInfo } from "react-icons/gr";
import { GrClose } from "react-icons/gr";
import { FaUserCheck } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import { GrPowerReset } from "react-icons/gr";
import { AiOutlineCloseCircle } from "react-icons/ai";

// Main Component
const ManageRequests = () => {
  const [activeTab, setActiveTab] = useState("requests");
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterOption, setFilterOption] = useState(null);

  const [members, setMembers] = useState([]); // To store fetched data
  const [loadingMemberId, setLoadingMemberId] = useState(null); // To track the member being processed
  const [deletingMemberId, setDeletingMemberId] = useState(null);

  // Modal State
  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [selectedMember, setSelectedMember] = useState(null);

  // Search Modal Config
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMembers, setFilteredMembers] = useState([]); // Stores search results

  // Open the search modal
  const openSearchModal = () => {
    setShowSearchModal(true);
  };

  // Close the search modal
  const closeSearchModal = () => {
    setShowSearchModal(false);
  };

  // Handle search input change
  const handleSearchInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Confirm the search action
  const confirmSearch = () => {
    const term = searchTerm.toLowerCase();
    const results = members.filter(
      (member) =>
        member.firstName?.toLowerCase().includes(term) ||
        member.surName?.toLowerCase().includes(term) ||
        member.email?.toLowerCase().includes(term)
    );
    setFilteredMembers(results);
    closeSearchModal(); // Close the search modal after confirming
  };

  // Reset the search and show all members
  const resetSearch = () => {
    setSearchTerm(""); // Clear search term
    setFilteredMembers(members); // Reset to show all members
  };

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://seedafriqueswegp-backend.onrender.com/api/members/requests"
        );
        const result = await response.json();
        if (result.data && Array.isArray(result.data)) {
          setMembers(result.data);
          setFilteredMembers(result.data); // Initialize filteredMembers with all data
        } else {
          setMembers([]);
          setFilteredMembers([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setMembers([]);
        setFilteredMembers([]);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  // Update the approval state and send approval email
  // manageRequests.js

  const updateApprovalState = async (id) => {
    setLoadingMemberId(id); // Set the loading state for the member being approved
    try {
      const response = await fetch(
        `https://seedafriqueswegp-backend.onrender.com/api/members/accept-request/${id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        // Update the member's approval state in the UI
        setMembers((prevMembers) =>
          prevMembers.map((member) =>
            member._id === id ? { ...member, approvalState: true } : member
          )
        );
        window.location.reload(); // Reload the page after success
        alert("Member approved successfully and email sent!");
        handleCloseModal(); // Close the modal after success
      } else {
        console.error("Failed to approve member.");
        alert("Error: Could not approve the member.");
      }
    } catch (error) {
      console.error("Error approving member:", error);
    } finally {
      setLoadingMemberId(null); // Reset loading state
    }
  };

  // Function to handle member deletion
  const handleDeleteMember = async (id) => {
    setDeletingMemberId(id);
    try {
      const response = await fetch(
        `https://seedafriqueswegp-backend.onrender.com/api/members/decline-request/${id}`,
        {
          method: "DELETE",
        }
      );

      if (response.ok) {
        // Remove the member from the UI after successful deletion
        setMembers((prevMembers) =>
          prevMembers.filter((member) => member._id !== id)
        );
        alert("Member declined and removed successfully!");
        handleCloseModal(); // Close the modal after success
      } else {
        console.error("Failed to delete member");
        alert("Error: Could not decline the member.");
      }
    } catch (error) {
      console.error("Error deleting member:", error);
    } finally {
      setDeletingMemberId(null);
    }
  };

  if (isLoading) {
    return <Skeleton />;
  }

  if (members.length === 0) {
    return <p>No members found.</p>;
  }

  // Function to dynamically set the text based on activeTab
  const renderTabTitle = () => {
    if (activeTab === "requests") {
      return "Manage Requests";
    } else if (activeTab === "members") {
      return "All Members";
    } else if (activeTab === "filter") {
      return "Filter Requests";
    }
  };

  // Handle filter option selection
  const handleFilterOption = (option) => {
    setFilterOption(option);
    setShowFilterModal(false); // Close the modal after selecting
  };

  // Open More Info Modal and pass member data
  const handleMoreInfo = (member) => {
    setSelectedMember(member);
    setShowDetailsModal(true);
  };

  // Close the More Info Modal
  const handleCloseModal = () => {
    setShowDetailsModal(false);
    setSelectedMember(null);
  };

  return (
    <div>
      {/* Navigation Bar */}
      <div className="flex items-center justify-between p-2">
        {/* Text to show what is currently open */}
        <div className="p-2 bg-[#7FC797] rounded-lg">
          <h2 className="sm:text-xl text-lg font-semibold">
            {renderTabTitle()}
          </h2>
        </div>

        {/* Navigation icons on the right */}
        <nav className="flex space-x-2 relative">
          {/* Search bar */}
          <div
            className="bg-blue-400 cursor-pointer p-2 rounded-lg"
            onClick={openSearchModal}
          >
            <FaSearch size={24} />
          </div>

          <div
            className={`cursor-pointer p-2  rounded-lg ${
              activeTab === "requests"
                ? "bg-[#C39B36]  text-blue-600 "
                : "bg-[#7FC797] "
            }`}
            onClick={() => setActiveTab("requests")}
          >
            <RiUserAddLine size={24} />
          </div>

          <div
            className={`cursor-pointer p-2  rounded-lg ${
              activeTab === "members"
                ? "bg-[#C39B36]  text-blue-600 "
                : "bg-[#7FC797] "
            }`}
            onClick={() => setActiveTab("members")}
          >
            <IoIosPeople size={24} />
          </div>

          <div className="p-2 bg-[#7FC797] rounded-lg">
            <LuSettings2
              className={`cursor-pointer ${
                activeTab === "filter" ? "text-blue-600" : ""
              }`}
              onClick={() => setShowFilterModal(!showFilterModal)}
              size={24}
            />
          </div>

          {/* Filter Modal */}
          {showFilterModal && (
            <div className="absolute top-12 right-0 bg-white shadow-md rounded-lg p-4 w-40 z-10">
              <h4 className="text-gray-800 font-semibold mb-2">Filter By</h4>
              <ul>
                <li
                  className="cursor-pointer hover:bg-gray-200 p-2 rounded-md"
                  onClick={() => handleFilterOption("birthday")}
                >
                  Birthday
                </li>
                <li
                  className="cursor-pointer hover:bg-gray-200 p-2 rounded-md"
                  onClick={() => handleFilterOption("ascending")}
                >
                  Ascending
                </li>
                <li
                  className="cursor-pointer hover:bg-gray-200 p-2 rounded-md"
                  onClick={() => handleFilterOption("descending")}
                >
                  Descending
                </li>
              </ul>
            </div>
          )}
        </nav>
      </div>

      {/* Conditional Rendering based on activeTab */}

      <div className="mt-4">
        {activeTab === "requests" && (
          <Requests
            handleMoreInfo={handleMoreInfo}
            members={filteredMembers.filter((member) => !member.approvalState)}
            updateApprovalState={updateApprovalState}
            loadingMemberId={loadingMemberId}
            deletingMemberId={deletingMemberId}
            handleDeleteMember={handleDeleteMember}
          />
        )}
        {activeTab === "members" && (
          <Members
            handleMoreInfo={handleMoreInfo}
            members={filteredMembers.filter((member) => member.approvalState)}
            loadingMemberId={loadingMemberId}
            deletingMemberId={deletingMemberId}
            handleDeleteMember={handleDeleteMember}
          />
        )}
        {filterOption && <p>Selected Filter: {filterOption}</p>}
      </div>

      {showDetailsModal && (
        <>
          {/* The first 3 lines are the codes for making teh perfect modal */}
          <div className="fixed inset-0 bg-black bg-opacity-50 z-[52] flex justify-center items-center">
            <div className="bg-transparent rounded-lg relative w-[450px] max-h-[90vh] overflow-y-auto no-scrollbar">
              <div className="bg-deeperpalegreen p-6 rounded-lg relative mx-1 my-2 ">
                {/* Close Button */}
                <button
                  className="absolute top-2 right-2"
                  onClick={handleCloseModal}
                >
                  <GrClose size={20} />
                </button>
                {/* Modal Title */}
                <h2 className="text-xl font-bold mb-4">Member Details</h2>

                {selectedMember && (
                  <div>
                    <p>
                      <strong>Name:</strong>
                      <div>
                        {selectedMember.firstName} {selectedMember.surName}
                      </div>
                    </p>
                    <p>
                      <strong>Birthday:</strong>
                      <div>
                        {selectedMember.dob
                          ? new Date(selectedMember.dob).toLocaleDateString() // Assuming dob is a string in valid date format
                          : "N/A"}
                      </div>
                    </p>

                    <p>
                      <strong>Contact:</strong>
                      <div>{selectedMember.contact}</div>
                    </p>
                    <p>
                      <strong>Email:</strong>
                      <div>{selectedMember.email}</div>
                    </p>
                    <p>
                      <strong>Gender:</strong>
                      <div>{selectedMember.gender}</div>
                    </p>
                    <p>
                      <strong>Location:</strong>
                      <div>{selectedMember.location}</div>
                    </p>
                    <p>
                      <strong>Address:</strong>
                      <div>{selectedMember.address}</div>
                    </p>
                    <p>
                      <strong>Education level:</strong>
                      <div>{selectedMember.educationLevel}</div>
                    </p>
                    <p>
                      <strong>Institution:</strong>
                      <div>{selectedMember.institution}</div>
                    </p>
                    <p>
                      <strong>Stem Interests:</strong>
                      <div>
                        {selectedMember.stemInterest &&
                        selectedMember.stemInterest.length > 0 ? (
                          selectedMember.stemInterest.map((interest, index) => (
                            <div key={index}>- {interest}</div>
                          ))
                        ) : (
                          <div>No interests selected</div>
                        )}
                      </div>
                    </p>
                    <p>
                      <strong>Previous Experience:</strong>
                      <div>{selectedMember.previousExperience}</div>
                    </p>
                    <p>
                      <strong>Emergency Contact:</strong>
                      <div>{selectedMember.emergencyContact}</div>
                    </p>
                    <p>
                      <strong>Skills:</strong>
                      <div>{selectedMember.skillsTalents}</div>
                    </p>
                    <p>
                      <strong>Communication Method:</strong>
                      <div>{selectedMember.communicationMethod}</div>
                    </p>
                    <p>
                      <strong>How did you hear of us :</strong>
                      <div>{selectedMember.howDidYouHearOfUs}</div>
                    </p>
                    <p>
                      <strong>Why join SEEDAfrique:</strong>
                      <div>{selectedMember.whyJoinSeedafrique}</div>
                    </p>
                  </div>
                )}

                {/* Accept and Decline Buttons */}
                <div className="flex justify-end mt-2 space-x-1">
                  {/* Decline */}
                  <div className="bg-[#D24B36] p-2 rounded-lg">
                    <button
                      disabled={deletingMemberId === selectedMember._id}
                      onClick={() => handleDeleteMember(selectedMember._id)}
                      className="flex items-center justify-center space-x-1"
                    >
                      {deletingMemberId === selectedMember._id ? (
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                          ></path>
                        </svg>
                      ) : (
                        <GrClose size={24} />
                      )}
                    </button>
                  </div>

                  {/* Accept */}
                  {/* Conditionally render the Accept button if approvalState is false */}
                  {!selectedMember.approvalState && (
                    <div className="bg-[#7FC797] p-2 rounded-lg">
                      <button
                        disabled={loadingMemberId === selectedMember._id} // Disable when loading
                        onClick={() => updateApprovalState(selectedMember._id)}
                        className="flex items-center justify-center space-x-1"
                      >
                        {loadingMemberId === selectedMember._id ? (
                          <svg
                            className="animate-spin h-5 w-5 text-white text-center"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                          </svg>
                        ) : (
                          <FaUserCheck size={24} />
                        )}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {/* Search Modal */}
      {showSearchModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-[52] flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg w-2/3 sm:w-1/3 relative">
            <div
              className="flex justify-end text-red-500"
              onClick={closeSearchModal}
            >
              <AiOutlineCloseCircle size={20} />
            </div>
            <h2 className="text-lg font-bold mb-4">Search For Member</h2>
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchInputChange}
              placeholder="Enter Member Name"
              className="w-full border border-gray-300 rounded-md p-2 mb-4"
            />
            <div className="flex  items-center space-x-2">
              <button
                onClick={confirmSearch}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Search
              </button>
              <div
                className="bg-gray-300 p-3 rounded-full"
                onClick={resetSearch}
              >
                <GrPowerReset size={20} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

//Components for Requests
const Requests = ({
  handleMoreInfo,
  members,
  updateApprovalState,
  loadingMemberId,
  deletingMemberId,
  handleDeleteMember,
}) => {
  // Check if there are no members in the filtered list (meaning no pending requests)
  if (!members || members.length === 0) {
    return (
      <div className="flex justify-center items-center h-64">
        <p className="text-lg text-gray-500">No new requests</p>
      </div>
    );
  }
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:gap-3 mx-1 rounded-md">
      {Array.isArray(members) &&
        members
          .filter((member) => member.approvalState === false) // Safely filter members
          .map((member) => (
            <div key={member._id} className=" bg-aboutbg  rounded-md p-1 my-1">
              <div className="border-2 border-black rounded-md p-2 my-0.5">
                <h1 className="text-base font-Poppins">
                  {" "}
                  Name:{" "}
                  <span className="font-bold font-Poppins">
                    {member.firstName} {member.surName}{" "}
                  </span>
                </h1>

                <div className="flex items-center justify-between my-2 space-x-1">
                  <div className="flex space-x-1">
                    {/* Birthday */}
                    <div className="flex bg-[#E3B3BE] p-2 rounded-lg">
                      {/* Icon */}
                      <div>
                        <LiaBirthdayCakeSolid size={24} />
                      </div>

                      {/* Birthday Date */}
                      <div>
                        {" "}
                        {member.dob
                          ? new Date(member.dob).toLocaleDateString() // Assuming dob is a string in valid date format
                          : "N/A"}
                      </div>
                    </div>

                    {/* More info */}
                    <div
                      className="bg-[#76E4EC] p-2 rounded-lg cursor-pointer"
                      onClick={() => handleMoreInfo(member)}
                    >
                      <GrContactInfo size={24} />
                    </div>
                  </div>
                  <div className="flex space-x-1">
                    {/* Decline */}
                    <div className="bg-[#D24B36] p-2 rounded-lg">
                      <button
                        disabled={deletingMemberId === member._id}
                        onClick={() => handleDeleteMember(member._id)}
                        className="flex items-center justify-center space-x-1"
                      >
                        {deletingMemberId === member._id ? (
                          <svg
                            className="animate-spin h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                          </svg>
                        ) : (
                          <GrClose size={24} />
                        )}
                      </button>
                    </div>

                    {/* Accept with loading spinner */}
                    <div className="bg-[#7FC797] p-2 rounded-lg">
                      <button
                        disabled={loadingMemberId === member._id} // Disable when loading
                        onClick={() => updateApprovalState(member._id)}
                        className="flex items-center justify-center space-x-1"
                      >
                        {loadingMemberId === member._id ? (
                          <svg
                            className="animate-spin h-5 w-5 text-white text-center"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                          </svg>
                        ) : (
                          <FaUserCheck size={24} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
};

// Components for Members, and filter
const Members = ({
  handleMoreInfo,
  members,
  updateApprovalState,
  loadingMemberId,
  deletingMemberId,
  handleDeleteMember,
}) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 sm:gap-3 mx-1 rounded-md pb-3">
      {Array.isArray(members) &&
        members
          .filter((member) => member.approvalState === true) // Safely filter members
          .map((member) => (
            <div key={member._id} className=" bg-aboutbg rounded-md p-1 my-1">
              <div className="border-2 border-black rounded-md p-2 my-0.5">
                <h1 className="text-base font-Poppins">
                  {" "}
                  Name:{" "}
                  <span className="font-bold font-Poppins">
                    {member.firstName} {member.surName}{" "}
                  </span>
                </h1>

                <div className="flex items-center justify-between my-2 space-x-1">
                  <div className="flex space-x-1">
                    {/* Birthday */}
                    <div className="flex bg-[#E3B3BE] p-2 rounded-lg">
                      {/* Icon */}
                      <div>
                        <LiaBirthdayCakeSolid size={24} />
                      </div>

                      {/* Birthday Date */}
                      <div>
                        {" "}
                        {member.dob
                          ? new Date(member.dob).toLocaleDateString() // Assuming dob is a string in valid date format
                          : "N/A"}
                      </div>
                    </div>

                    {/* More info */}
                    <div
                      className="bg-[#76E4EC] p-2 rounded-lg cursor-pointer"
                      onClick={() => handleMoreInfo(member)}
                    >
                      <GrContactInfo size={24} />
                    </div>
                  </div>
                  <div className="flex space-x-1">
                    {/* Decline */}
                    <div className="bg-[#D24B36] p-2 rounded-lg">
                      <button
                        disabled={deletingMemberId === member._id}
                        onClick={() => handleDeleteMember(member._id)}
                        className="flex items-center justify-center space-x-1"
                      >
                        {deletingMemberId === member._id ? (
                          <svg
                            className="animate-spin h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            ></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
                            ></path>
                          </svg>
                        ) : (
                          <GrClose size={24} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
    </div>
  );
};

export default ManageRequests;

// Skeleton card component
const SkeletonCard = () => (
  <div className="animate-pulse space-y-1 p-2 rounded-lg">
    <div className="w-full h-28 bg-gray-300 rounded"></div>
  </div>
);

// Skeleton loader component
const Skeleton = () => {
  // Array to represent the number of skeleton cards needed
  const skeletonArray = Array.from({ length: 20 });

  return (
    <div>
      <div className="flex justify-between mx-2 mt-2 animate-pulse mb-5">
        <div className="w-36 h-10 bg-gray-300 rounded"></div>
        <div className="w-36 h-10 bg-gray-300 rounded"></div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-4">
        {skeletonArray.map((_, index) => (
          <SkeletonCard key={index} />
        ))}
      </div>
    </div>
  );
};

// Commit Save 3