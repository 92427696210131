import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Blurhash } from "react-blurhash"; // Include this if using Blurhash

const EventDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const event = location.state?.event || {}; // Access event details passed via state

  return (
    <div className="min-h-screen bg-[#e5ecda] flex flex-col items-center py-10 px-4">
      {/* Event Details Card */}
      <div className="w-full max-w-md mx-auto pb-3 rounded-2xl shadow-lg flex flex-col items-center bg-deeperpalegreen">
        {/* Image Section */}
        <div className="w-full h-auto relative rounded-2xl overflow-hidden mb-4">
          {!event.imageUrl && (
            <div className="w-full h-64 inset-0 rounded-2xl overflow-hidden">
              <Blurhash
                hash={
                  event.blurHash ||
                  "VT5ap~fQeogff*d8e:e:b]fQcqf*e.fkf7gfg2f+e.eo"
                }
                width="100%"
                height="100%"
                resolutionX={32}
                resolutionY={32}
                punch={1}
              />
            </div>
          )}
          {event.imageUrl && (
            <img
              src={event.imageUrl}
              alt={event.title || "Event Image"}
              className="w-full h-auto object-cover rounded-2xl"
            />
          )}
          <div className="absolute top-2 right-2 text-center bg-lime-50 border-4 border-green-800 text-green-800 text-xs font-bold rounded-lg p-2">
            <p>{event.day || "Day"}</p>
            <p>{event.dayNumber || "--"}</p>
            <p>{`${event.month || "Month"} ${event.year || "Year"}`}</p>
          </div>
        </div>

        {/* Event Category */}
        <div className="ml-7 w-full items-start">
          <h2 className="right-0 w-2/4 text-sm bg-green-800 text-lime-50 text-center mt-2 py-1 px-3 rounded-md font-Poppins">
            {event.category || "Uncategorized"}
          </h2>
        </div>

        {/* Event Title */}
        <h1 className="text-lg text-left w-full font-semibold text-green-950 mt-2 px-4 font-Poppins">
          {event.title || "Untitled Event"}
        </h1>

        {/* Event Description */}
        <p
          className="text-emerald-900 text-left w-full text-sm px-4 font-Poppins mt-3 leading-relaxed"
          dangerouslySetInnerHTML={{
            __html: event.description || "No description available.",
          }}
        ></p>

        {/* Venue and Register Links */}
        <div className="flex justify-between items-center mt-5 mb-2 px-4 w-full">
          <a
            className="text-emerald-900 underline text-left"
            href={event.googleMapLink || "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {event.venue || "No venue specified"}
          </a>
          <a
            className="text-blue-600 underline"
            href={event.registrationLink || "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            Register
          </a>
        </div>
      </div>

      <div className="flex flex-wrap justify-center sm:justify-start space-x-4 mt-6">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)} // Navigate back to the previous page
          className="bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-600 transition duration-200"
        >
          Back
        </button>

        {/* All Events Button */}
        <button
          onClick={() => navigate("/upcomingevents")} // Navigate to the events list page
          className="bg-green-500 text-white px-4 py-2 rounded shadow hover:bg-green-600 transition duration-200"
        >
          All Events
        </button>
      </div>
    </div>
  );
};

export default EventDetailsPage;
